import React from 'react';
import styled from 'styled-components';
import PageSeo from '../components/Seo/PageSeo';
import PageTitle from '../components/PageTitle';
import PageSection from '../components/PageSection';
import { respondTo } from '../styles/shared/breakpoints';
import { siteMapCopy } from '../copy/sitemap-new.js';
import { Sitemap } from '../components';

//SEO
const pageMeta = {
  title: 'Site map | WELIREG® (belzutifan) for Health Care Professionals',
  keywords: '',
  description:  'Health care professionals may use this site map to browse the content about WELIREG® (belzutifan). Select a link and quickly access any page on Welireghcp.com.',
  schemaJsonLD: [
    `{"@context":"https://schema.org/","url":"https://www.welireghcp.com/site-map/","@type":"MedicalWebPage","name":"Site Map"}`,
    `{"@context":"https://schema.org/","url":"https://www.welireghcp.com/site-map/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended only for residents of the United States, its territories, and Puerto Rico."}`
  ]
}

const PageSectionSiteMap = styled(PageSection)`
  padding-top: 0px;
  padding-bottom: 0px;
`

const SiteMapPage = () => {
  return (
    <>
      <PageSeo pageMeta={pageMeta} >
        <link rel="canonical" href="https://www.welireghcp.com/site-map/" />
      </PageSeo>
      <PageTitle unbranded>
        { siteMapCopy.pageTitle }
      </PageTitle>

      <PageSectionSiteMap>
        <Sitemap siteMapCategories = { siteMapCopy.categories } />
      </PageSectionSiteMap>
    </>
  );
};


export default SiteMapPage;