import React from "react";

export const siteMapCopy = {
  pageTitle: 'Site Map',

  categories:
  [
    {
      categoryName: 'General',
      links: [
        {
          label: 'Home',
          href: '/',
        },
        {
          label: 'Home for Advanced RCC',
          href: '/advanced-renal-cell-carcinoma/',
        },
        {
          label: <>Home for VHL Disease&#8288;&#8211;&#8288;associated Tumors</>,
          href: '/von-hippel-lindau/',
        },
        {
          label: 'Contact Us',
          href: '/contact-us/',
        },
      ],
    },
    {
      categoryName: 'Mechanism of Action',
      links: [
        {
          label: 'MOA in VHL Disease',
          href: '/von-hippel-lindau/mechanism-of-action/',
        },
        {
          label: 'MOA in Advanced RCC',
          href: '/advanced-renal-cell-carcinoma/mechanism-of-action/',
        },
      ],
    },
    {
      categoryName: 'Efficacy',
      links: [
        {
          label: <>VHL Disease&#8288;&#8211;&#8288;associated Tumor Efficacy Data From LITESPARK-004</>,
          href: '/von-hippel-lindau/efficacy/',
        },
        {
          label: 'Advanced RCC Efficacy Data From LITESPARK-005',
          href: '/advanced-renal-cell-carcinoma/efficacy/',
        },
      ],
    },
    {
      categoryName: 'Safety',
      links: [
        {
          label: <>VHL Disease&#8288;&#8211;&#8288;associated Adverse Reaction Data From LITESPARK-004</>,
          href: '/von-hippel-lindau/adverse-reaction-profile/',
        },
        {
          label: 'Advanced RCC Adverse Reaction Data From LITESPARK-005',
          href: '/advanced-renal-cell-carcinoma/safety/',
        },
      ],
    },
    {
      categoryName: 'Dosing',
      links: [
        {
          label: <>Dosing for VHL Disease&#8288;&#8211;&#8288;associated Tumors</>,
          href: '/von-hippel-lindau/dosing/',
        },
        {
          label: 'Dosing for Advanced RCC',
          href: '/advanced-renal-cell-carcinoma/dosing/',
        },
      ],
    },
    {
      categoryName: 'Resources', 
      links: [
        {
          label: 'What Is VHL Disease?',
          href: '/von-hippel-lindau/what-is-von-hippel-lindau/',
        },
        {
          label: <>Guidelines for VHL Disease&#8288;&#8211;&#8288;associated Tumors</>,
          href: '/von-hippel-lindau/guidelines/',
        },
        {
          label: 'Treatment Sequencing for Advanced RCC',
          href: '/advanced-renal-cell-carcinoma/treatment-considerations/',
        },
        {
          label: <>Resources & Dispensing for VHL Disease&#8288;&#8211;&#8288;associated Tumors</>,
          href: '/von-hippel-lindau/resources/',
        },
        {
          label: 'Resources & Dispensing for Advanced RCC',
          href: '/advanced-renal-cell-carcinoma/resources-and-access/',
        },
      ],
    },
  ]
};
